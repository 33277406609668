export function checkIfExist(obj, list) {
    for (let i = 0; i < list.length; i++) {
        if (list[i].name == obj)
            return true;
    }
    return false;
}

export function isInProject(userList, currentUser) {
    for (const user of userList) {
        if (user.name.toLowerCase() == currentUser.toLowerCase())
            return true
    }
    return false
}
